import React, { useEffect, useState } from "react";

import {axiosInstance} from "../../api/axiosInstance";

import { toast } from "react-toastify";

import { Button, Form, Flex, theme } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import ModalComponent from "../../components/ModalComponent/ModalComponent";

import "./LocationAdministrationPage.css";
import LocationAdministrationForm from "../../components/Administration/Locations/LocationAdministrationForm/LocationAdministrationForm";
import LocationAdministrationTable from "../../components/Administration/Locations/LocationAdministrationTable/LocationAdministrationTable";
import LocationAdministrationFilter from "../../components/Administration/Locations/LocationAdministrationFilter/LocationAdministrationFilter";

const LocationAdministrationPage = (props) => {

  const [mode, setMode] = useState("insert");

  const [filterForm] = Form.useForm();
  const [locationDataForm] = Form.useForm();
  const [rowData, setRowData] = useState(null);

  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [loading, setLoading] = useState(false);
  const [locationAdministrationModalOpened, setLocationAdministrationModalOpened] =
    useState(false);

  const { token } = theme.useToken();

  const [initialValues, setInitialValues] = useState({
    cadastralParcel: '',
    cadastralMunicipality: '' ,
    address: {street: '', houseNumber: '', postNumber: '', city: ''},
    county: '',
  
  });

  const pageStyle = {
    maxWidth: "none",
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
    height: "100%",
  };

    const [owners, setOwners] = useState([]);

    const fetchLocations = async (values) => {
    const response = await axiosInstance.get(
      `/api/v1/locations/find-by?searchTerm=${values.searchTerm}&page=${pageNumber - 1}&size=${pageSize}`
    );

    setTableData(
      response?.data?.content.map((element) => {
        return {
          ...element,
          key: element.id,
          addressStr: element.address.street
            ? `${element.address.street} ${element.address.houseNumber ? element.address.houseNumber + "," : ''} ${
                element.address.postNumber ? element.address.postNumber : ""
              } ${element.address.city ? element.address.city : ""}`
            : ""
        };
      })
    );
    setTotalRows(response?.data?.totalElements);
  };

    useEffect(() => {
    fetchLocations({ searchTerm:"" });
  }, [pageNumber, pageSize]);

  const onFormFilterSubmit = async (values) => {
    fetchLocations(values);
  };

  const onLocationDataSubmit = (values) => {
    if (mode === "insert") {
      axiosInstance
        .post("/api/v1/locations", values)
        .then((res) => {
          setLoading(false);
          setLocationAdministrationModalOpened(false);
          locationDataForm.resetFields();
          toast.success("Lokacija uspješno spremljena!");
          fetchLocations({ searchTerm: "" });
        })
        .catch((ex) => {
          toast.error("Došlo je do greške prilikom spremanja lokacije!");
        });
    } else if (mode === "edit") {
      axiosInstance
        .patch("/api/v1/locations", values)
        .then((res) => {
          setLoading(false);
          setLocationAdministrationModalOpened(false);
          locationDataForm.resetFields();
          toast.success("Lokacija uspješno ažurirana!");
          fetchLocations({ searchTerm: "" });
        })
        .catch((ex) => {
          toast.error("Došlo je do greške prilikom ažuriranja lokacije!");
        });
    }
  };

  const onFilterChanged = async (pagination, filters, sorter) => {
    setPageNumber(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleSubmitLocation = async () => {
    try {
      const validationResult = await locationDataForm.validateFields();
      if (!validationResult.errorFields) {
        locationDataForm.submit();
        setLoading(true);
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const locationDataFormTitle = () => {
    if (mode === "insert") {
      return "Nova lokacija";
    } else if (mode === "edit") {
      return "Uređivanje lokacije";
    } else {
      return "Pregled detalja lokacije";
    }
  };


  return (
    <div className={"location-page"} style={pageStyle}>
              <h3>Administracija lokacija</h3>
      <LocationAdministrationFilter
        form={filterForm}
        onFormFilterSubmit={onFormFilterSubmit}
      ></LocationAdministrationFilter>
      <Flex vertical={false} justify="right" className="add-location-wrapper">
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          style={{ marginTop: "32px" }}
          onClick={async () => {
              locationDataForm.resetFields();
              setMode("insert");
              setLocationAdministrationModalOpened(true);
          }}
        >
          Nova lokacija
        </Button>
      </Flex>
      <LocationAdministrationTable
        tableData={tableData}
        onFilterChanged={(pagination, filters, sorter) =>
          onFilterChanged(pagination, filters, sorter)
        }
        setMode={setMode}
        setLocationAdministrationModalOpened={setLocationAdministrationModalOpened}
        setRowData={setRowData}
        style={{ marginTop: "32px" }}
        pageNumber={pageNumber}
        pageSize={pageSize}
        totalRows={totalRows}
        bordered
        refreshData={() =>
          fetchLocations({ searchTerm: "" })
        }
      />
      <ModalComponent
        title={locationDataFormTitle()}
        show={locationAdministrationModalOpened}
        onOk={handleSubmitLocation}
        onCancel={() => {
          setLocationAdministrationModalOpened(false);
        }}
        loading={loading}
        showFooter={mode !== "preview"}
        width={"580px"}
      >
        <LocationAdministrationForm
          form={locationDataForm}
          mode={mode}
          onFinish={onLocationDataSubmit}
          initialValues={mode === "insert" ? initialValues : rowData}
          disabled={mode === "preview"}
        ></LocationAdministrationForm>
      </ModalComponent>
    </div>
  );
};

export default LocationAdministrationPage;
