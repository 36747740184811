import React from "react";
import {Button, Flex, Form, Select, Input, Space, theme, DatePicker} from "antd";

import dayjs from "dayjs";

const EmployeeTimeTrackingFilter = ({form, users, onFormFilterSubmit, setUserId}) => {
    const {token} = theme.useToken();

    const formStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
        borderColor: "rgba(0, 0, 0, 0.03)",
        borderStyle: "solid",
        borderWidth: "thin",
    };

    return (
        <Form
            form={form}
            name="employeeTimeTrackingFilter"
            style={formStyle}
            onFinish={onFormFilterSubmit}
            layout={"vertical"}
            initialValues={{
                userId: "",
                year: dayjs(),
                month: dayjs().month() + 1
            }}
        >
            <Flex vertical>
                <Form.Item
                    name={"userId"}
                    label={`Zaposlenik`}
                    rules={[
                        {
                            required: false,
                            message: "Zaposlenik je obavezno polje!",
                        },
                    ]}
                >
                    <Select
                        options={users}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={(value) => setUserId(value)}
                    ></Select>
                </Form.Item>
                <Form.Item
                    name={"year"}
                    label={`Godina`}
                    rules={[
                        {
                            required: true,
                            message: "Godina je obavezno polje!",
                        },
                    ]}
                >
                    <DatePicker
                        picker="year"
                        style={{width: '100%'}}
                    />
                </Form.Item>
                <Form.Item
                    name={"month"}
                    label={`Mjesec:`}
                    rules={[
                        {
                            required: false,
                            message: "Mjesec je obavezno polje!",
                        },
                    ]}
                >
                    <Select
                        options={[
                            {value: '', label: 'Odaberite mjesec'},
                            {value: 1, label: 'Siječanj'},
                            {value: 2, label: 'Veljača'},
                            {value: 3, label: 'Ožujak'},
                            {value: 4, label: 'Travanj'},
                            {value: 5, label: 'Svibanj'},
                            {value: 6, label: 'Lipanj'},
                            {value: 7, label: 'Srpanj'},
                            {value: 8, label: 'Kolovoz'},
                            {value: 9, label: 'Rujan'},
                            {value: 10, label: 'Listopad'},
                            {value: 11, label: 'Studeni'},
                            {value: 12, label: 'Prosinac'}
                        ]}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                    ></Select>
                </Form.Item>

                <Flex
                    justify="end"
                    align="center"
                    className="button-container"
                    gap={"4px"}
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{minWidth: "120px"}}
                    >
                        Pretraži
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                        }}
                        style={{minWidth: "120px"}}
                    >
                        Poništi filtere
                    </Button>
                </Flex>
            </Flex>
        </Form>
    );
};

export default EmployeeTimeTrackingFilter;
