import React from "react";

import {Form, Input} from "antd";
import {axiosInstance} from "../../../../api/axiosInstance";
import {toast} from "react-toastify";

const PasswordChangeForm = ({form, rowData, setPasswordChangeModalOpened}) => {

    const handleChangePassword = (values) => {
        axiosInstance.post("/api/v1/users/change-password-by-admin", {
            id: rowData?.id,
            password: values.password
        }).then(res => {
            toast.success("Lozinka uspješno promijenjena");
            form.resetFields();
            setPasswordChangeModalOpened(false);
        }).catch(ex => {
            toast.error("Došlo je do greške prilikom promjene lozinke.")
        })
    }

    return (
        <Form
            form={form}
            layout={"vertical"}
            name={"passwordChangeForm"}
            onFinish={(values) => {
                handleChangePassword(values);
            }}
            initialValues={{password: ''}}
        >
            <Form.Item
                name={`password`}
                label={`Nova lozinka:`}
                rules={[{required: true, message: 'Lozinka je obavezno polje'},
                    { min: 8, message: 'Lozinka mora imati duljinu od minimalno 8 znakova' },
                    {
                        pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
                        message: 'Lozinka mora sadržavati bar jedno malo i veliko slovo, broj i posebni znak'
                    }]}
            >
                <Input.Password
                    placeholder="Lozinka"
                />
            </Form.Item>
            <Form.Item
                hasFeedback
                name={`confirmPassword`}
                label={`Potvrda lozinke:`}
                dependencies={["password"]}
                rules={[
                    {
                        required: true,
                        message: "Potvrda lozinke je obavezno polje!",
                    },
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error("Lozinka se razlikuje!"));
                        },
                    }),
                ]}
            >
                <Input.Password placeholder="Potvrda lozinke"/>
            </Form.Item>
        </Form>
    );

}

export default PasswordChangeForm;