import React, {useEffect} from "react";
import { Dropdown, theme } from "antd";
import { Spin, Flex } from "antd";

const CustomEvent = ({
  event,
  handleEditEvent,
  handleDeleteEvent,
  eventActionLoading,
}) => {

  const {
    token: { colorBgLayout, colorTextTertiary },
  } = theme.useToken();

  useEffect(() => {
    console.log(event)
  }, [event]);

  const onClick = ({ key }) => {
    if (key === "1") {
      handleEditEvent();
    } else {
      handleDeleteEvent();
    }
  };

  const items = [
    {
      label: "Izmjeni unos",
      key: "1",
    },
    {
      label: "Obriši utrošak",
      key: "2",
    },
  ];

  return (
    <Dropdown menu={{ items, onClick }} trigger={["contextMenu"]} style={{height: '100%'}} disabled={eventActionLoading}>
      <div>
        {!eventActionLoading ? (
          <div style={{height: '80px'}}>
            <div style={{marginTop: '16px'}}>
              <p style={{margin: 0, padding: 0}}>{event.workType ? `Vrsta rada: ${event.workType}` : ""}</p>
              { event.title && event.title !== "null - null" ?
              <p style={{margin: 0, padding: 0}}>{event.title ? `Posao: ${event.title}` : ""}</p>
                  : <></>
              }
              <p style={{margin: 0, padding: 0}}>{event.totalHours ? `Utrošak sati: ${event.totalHours} h` : ""}</p>
            </div>
          </div>
        ) : (
            <div
                style={{justifyContent: "center", alignItems: 'center', display: 'flex', height: '100%' }}
          >
            <Spin />
          </div>
        )}
      </div>
    </Dropdown>
  );
};

export default CustomEvent;
