import {Button, Checkbox, DatePicker, Flex, Form, Input, InputNumber, Modal, Select, theme} from "antd";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import moment from 'moment';
import {ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {axiosInstance} from "../../api/axiosInstance";
import {toast} from "react-toastify";

const TimeTrackingItemForm = ({form, mode, initialValues, projects, workTypeId}) => {

    useEffect(() => {console.log(initialValues, 'initialValuesItemForm')}, [initialValues])

    const {confirm} = Modal;

    const [startDate, setStartDate] = useState();
    const {token} = theme.useToken();

    const {RangePicker} = DatePicker;

    const disabledTime = (current, type) => {

        const range = (start, end) => {
            const result = [];
            for (let i = start; i < end; i++) {
                result.push(i);
            }
            return result;
        }

        if (type === "start") {
            // Disable times before 07:00 AM for the start date
            return {
                disabledHours: () => range(0, 7).concat(range(16, 24))
            }
        } else {
            if (startDate) {
                return {
                    disabledHours: () => range(0, dayjs(startDate).hour()).concat(range(16, 24)),
                };
            }
            // Disable times after 03:00 PM for the end date
            return (
                current && current > moment().set({hour: 15, minute: 0, second: 0})
            );
        }
    };

    const disabledDate = (current) => {
        // Disable dates before the start date and the same date as the start date

        if (!startDate) {
            return dayjs().isBefore(current, "day");
        }

        return (
            (current && dayjs(startDate).isBefore(current, "day")) ||
            dayjs(startDate).isAfter(current, "day")
        );
    };

    const showConfirm = (remove, fieldName, fields) => {
        const itemId =
            form.getFieldValue("items")[fields[fieldName]?.name]?.itemId;
        confirm({
            icon: <ExclamationCircleOutlined/>,
            centered: true,
            title: "Upozorenje",
            content: "Jeste li sigurni da želite obrisati stavku?",
            okText: "Potvrdi",
            cancelText: "Odustani",
            onOk() {
                remove(fieldName);
            },
            onCancel() {
            },
        });
    };

    const handleStartDateChange = (value) => {
        if (value && value.length > 0) {
            const startOfDay = value[0];
            setStartDate(startOfDay);
        } else {
            setStartDate(null);
        }
    };

    if(!workTypeId || workTypeId !== 1)
        return <React.Fragment></React.Fragment>

    return (<>
        <Form
            form={form}
            initialValues={initialValues}
            name="timeTrackingItemForm"
            autoComplete="off"
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}

            labelCol={{span: 24}} // Make label take full row
            wrapperCol={{span: 24}} // Make input take full row
        >
            <Form.List name="items">
                {(fields, {add, remove}, {errors}) => (
                    <>
                    {fields.map(({key, name, restField, getFieldValue}) => (
                        <>
                            {/*<h4 style={{marginLeft: '8px', marginTop: '32px'}}>Utrošak br.{name + 1}</h4>*/}
                            <div
                                key={"div" + key}
                                style={{
                                    marginTop: '32px',
                                    padding: "16px",
                                    background: "rgba(0, 0, 0, 0.02)",
                                }}
                            >
                                <Form.Item
                                    name={[name, "projectId"]}
                                    label={`Posao`}
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                if (value) {
                                                    const selectedProjects = form
                                                        .getFieldsValue()
                                                        .items.map((item) => item?.projectId);
                                                    const elementCount = selectedProjects.reduce(
                                                        (cnt, cur) => ((cnt[cur] = cnt[cur] + 1 || 1), cnt),
                                                        {}
                                                    );
                                                    if (selectedProjects.length > 1 && elementCount[value] > 1) {
                                                        return Promise.reject(new Error("Posao već postoji."));
                                                    } else {
                                                        return Promise.resolve(value);
                                                    }
                                                } else {
                                                    return Promise.reject(
                                                        new Error("Posao je obavezno polje.")
                                                    );
                                                }
                                            },
                                        },
                                    ]}
                                    key={"projectId" + key}
                                >
                                    <Select
                                        options={projects?.map((item) => ({
                                            label: item.title,
                                            value: item.projectId,
                                        }))}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={[name, "regularHours"]}
                                    label={`Redovni sati`}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Redovni sati su obavezno polje!",
                                        },
                                    ]}
                                    key={"regularHours" + key}
                                >
                                    <InputNumber
                                        step={1}
                                        min={0}
                                        max={8}
                                        style={{width: "100%"}}
                                        placeholder="Redovni sati"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={[name, "overtimeHours"]}
                                    label={`Prekovremeni rad`}
                                    rules={[
                                        {
                                            required: false,
                                            message: "Prekovremeni sati su obavezno polje!",
                                        },
                                    ]}
                                    key={"overtimeHours" + key}
                                >
                                    <InputNumber
                                        step={1}
                                        min={0}
                                        style={{width: "100%"}}
                                        placeholder="Prekovremeni rad"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={[name, "description"]}
                                    label={`Opis:`}
                                    rules={[
                                        {
                                            required: false,
                                            message: "Opis je obavezno polje!",
                                        },
                                    ]}
                                    key={"description" + key}
                                >
                                    <Input placeholder="Opis"/>
                                </Form.Item>
                                <Button
                                    danger
                                    icon={<MinusCircleOutlined/>}
                                    onClick={() => showConfirm(remove, name, fields)}
                                    style={{width: "100%", marginTop: "16px"}}
                                    key={"btn" + key}
                                    disabled={mode === 'edit'}
                                >
                                    Obriši stavku
                                </Button>
                            </div>
                        </>
                    ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => {
                                    add({projectId: '', regularHours: 0, overtimeHours: 0, description: ''})
                                }}
                                block
                                icon={<PlusOutlined/>}
                                style={{marginTop: "16px"}}
                                disabled={mode === 'edit'}
                            >
                                Dodaj novu stavku
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form>

    </>)
        ;

}

export default TimeTrackingItemForm;