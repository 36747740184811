import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";

import {axiosInstance} from "../../api/axiosInstance";
import axios from "axios";

import {toast} from "react-toastify";

import {Button, Form, Input, Flex, Card} from "antd";

import {
    TOKEN_ENDPOINT,
    AUTHORIZATION_SERVICE_URL,
    CLIENT_NAME,
    CLIENT_SECRET,
} from "../../constants/constants";
import "../LoginPage/LoginPage.css";
import {cleanStore, setAll} from "../../slices/userDataSlice";

const LoginPage = ({forgottenPassword, forgottenPasswordToken, setForgottenPassword, setForgottenPasswordToken}) => {
    const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const fetchUserInfo = async (accessToken) => {
        return await axios.get(AUTHORIZATION_SERVICE_URL + "/connect/v1/userinfo", {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        });
    };

    const fetchUnreadPosts = async () => {
        return await axiosInstance.get("/api/v1/noticeboard/unread-posts");
    };

    const onFinish = async (values) => {
        try {
            const tokenResponse = await axiosInstance.post(
                TOKEN_ENDPOINT,
                `grant_type=password&client_id=${CLIENT_NAME}&username=${values["username"]}&password=${values["password"]}`,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: "Basic " + btoa(`${CLIENT_NAME}:${CLIENT_SECRET}`),
                    },
                }
            );

            if (tokenResponse) {
                localStorage.setItem("accessToken", tokenResponse.data.access_token);
                localStorage.setItem("refreshToken", tokenResponse.data.refresh_token);

                const userInfoResponse = await fetchUserInfo(
                    tokenResponse.data.access_token
                );

                const unreadPostCountResponseArr = await fetchUnreadPosts();
                let isUiDisabled = false;
                try {
                    isUiDisabled = await axiosInstance.get("/api/v1/users/check-ui-disabled");
                } catch (ex) {
                    console.log("Dogodila se greška prilikom provjere unesenih sati djelatnika.")
                }

                dispatch(
                    setAll({
                        userId: userInfoResponse.data.userId,
                        user: userInfoResponse.data.user,
                        firstAndLastName: userInfoResponse.data.firstAndLastName,
                        authorities: userInfoResponse.data.authorities,
                        unreadPostCount: unreadPostCountResponseArr?.data?.length ? unreadPostCountResponseArr.data.length : 0,
                        unreadPostIds: unreadPostCountResponseArr?.data ? unreadPostCountResponseArr.data : [],
                        uiDisabled: isUiDisabled?.data
                    })
                );
            }
        } catch (ex) {
            switch (ex.response.data.error) {
                case "invalid_client":
                    toast.error(`Neispravno korisničko ime ili lozinka!`);
                    break;
                case "unauthorized_client":
                    toast.error(`Korisnički račun je neaktivan!`);
                    break;
                case "access_denied":
                    toast.error("Neispravno korisničko ime ili lozinka!");
                    break;
            }
        }
    };

    const LoginForm = () => {
        return (
            <Form
                name="loginForm"
                initialValues={{username: "", password: ""}}
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
                style={{minWidth: "300px"}}
            >
                <Form.Item
                    label="Korisničko ime"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: "Korisničko ime je obavezan podatak!",
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Lozinka"
                    name="password"
                    rules={[{required: true, message: "Lozinka je obavezan podatak!"}]}
                    style={{marginTop: "32px"}}
                >
                    <Input.Password/>
                </Form.Item>
                <Flex vertical={false} style={{justifyContent: "flex-end"}}>
                    <Button
                        type="link"
                        style={{
                            marginTop: "4px",
                            alignSelf: "flex-end",
                            justifySelf: "flex-end",
                            color: "black",
                            fontStyle: "italic",
                            margin: "0px",
                            padding: "0px",
                        }}
                        onClick={() => setForgottenPassword(true)}
                    >
                        Zaboravljena lozinka?
                    </Button>
                </Flex>
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{width: "100%", marginTop: "8px"}}
                >
                    Prijava
                </Button>
            </Form>
        );
    };

    const ForgottenPasswordMailConfirmationForm = () => {
        return (
            <Form
                name="mailConfirmationForm"
                initialValues={{email: ""}}
                onFinish={(values) => {
                    setResetPasswordLoading(true);
                    axiosInstance
                        .post(`/api/v1/users/reset-password?email=${values.email}`)
                        .then((res) => {
                            toast.success("E-mail za promjenu lozinke poslan!");
                            setForgottenPassword(false);
                        })
                        .catch((ex) => {
                            toast.error(ex.response.data.errorMessage);
                        })
                        .finally((onFinally) => {
                            setResetPasswordLoading(false);
                        });
                }}
                autoComplete="off"
                layout="vertical"
                style={{minWidth: "300px"}}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Email ime je obavezan podatak!",
                        },
                        {
                            type: "email",
                            message: "E-mail adresa je neispravna!",
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{width: "100%", marginTop: "8px"}}
                    loading={resetPasswordLoading}
                >
                    Pošalji novu lozinku
                </Button>
            </Form>
        );
    };

    const NewPaswordForm = () => {
        return (
            <Form
                name="newPasswordForm"
                initialValues={{password: "", confirmPassword: ""}}
                onFinish={(values) => {
                    axiosInstance
                        .post(`/api/v1/users/change-password`, {
                            token: forgottenPasswordToken,
                            password: values.password,
                        })
                        .then((res) => {
                            setResetPasswordLoading(true);
                            toast.success("Lozinka uspješno promijenjena!");
                            setForgottenPassword("");
                            setForgottenPasswordToken("");
                        })
                        .catch((ex) => {
                            toast.error(ex.response.data.errorMessage);
                        })
                        .finally((onFinally) => {
                            setResetPasswordLoading(false);
                        });
                }}
                autoComplete="off"
                layout="vertical"
                style={{minWidth: "300px"}}
            >
                <Form.Item
                    label="Nova lozinka"
                    name="password"
                    rules={[{required: true, message: "Lozinka je obavezan podatak!"}]}
                    style={{marginTop: "32px"}}
                >
                    <Input.Password placeholder={"Nova lozinka"}/>
                </Form.Item>
                <Form.Item
                    hasFeedback
                    name={`confirmPassword`}
                    label={`Potvrda lozinke:`}
                    dependencies={["password"]}
                    rules={[
                        {
                            required: true,
                            message: "Potvrda lozinke je obavezno polje!",
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("Lozinka se razlikuje!"));
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="Potvrda lozinke"/>
                </Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{width: "100%", marginTop: "8px"}}
                    loading={resetPasswordLoading}
                >
                    Promijeni lozinku
                </Button>
            </Form>
        );
    };

    const showForm = () => {
        if (forgottenPasswordToken) {
            return <NewPaswordForm></NewPaswordForm>;
        } else {
            if (!forgottenPassword) {
                return <LoginForm></LoginForm>;
            } else {
                return (
                    <ForgottenPasswordMailConfirmationForm></ForgottenPasswordMailConfirmationForm>
                );
            }
        }
    };

    return (
        <div className={"login-page"}>
            <Flex gap="middle" vertical={false}>
                <Flex vertical={true} style={{justifyContent: "center"}}>
                    {/* </Flex>p style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 30, color: 'white', alignSelf: 'center'}}><b>Energis ERP</b></p> */}
                    <div style={{
                        display: "flex",
                        flexDirection: 'column',
                        justifyContent: "center",
                        marginLeft: '92px'
                    }}>
                        <h1 style={{color: "whitesmoke"}}>Energis ERP</h1>
                    </div>
                    <Card>{showForm()}</Card>
                </Flex>
            </Flex>
            <div>
                <img
                    src={"./logo.svg"}
                    alt="logo"
                    width={550}
                    className="login-page-logo"
                    style={{
                        position: 'absolute',
                        bottom: '0',
                        right: '0',
                        marginBottom: '36px',
                        marginRight: '36px',
                        opacity: 0.2
                    }}
                />
            </div>
        </div>
    );
};

export default LoginPage;
