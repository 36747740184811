import React, { useEffect, useState } from "react";

import {axiosInstance} from "../../api/axiosInstance";

import { toast } from "react-toastify";

import { Button, Form, Flex, theme } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import ModalComponent from "../../components/ModalComponent/ModalComponent";

import CooperantAdministrationFilter from "../../components/Administration/Cooperants/CooperantAdministrationFilter/CooperantAdministrationFilter";

import "./CooperantAdministrationPage.css";
import CooperantAdministrationForm from "../../components/Administration/Cooperants/CooperantAdministrationForm/CooperantAdministrationForm";
import CooperantAdministrationTable from "../../components/Administration/Cooperants/CooperantAdministrationTable/CooperantAdministrationTable";

const CooperantAdministrationPage = (props) => {
  const [mode, setMode] = useState("insert");

  const [filterForm] = Form.useForm();
  const [cooperantDataForm] = Form.useForm();
  const [rowData, setRowData] = useState();

  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const [loading, setLoading] = useState(false);
  const [
    cooperantAdministrationModalOpened,
    setCooperantAdministrationModalOpened,
  ] = useState(false);

  const { token } = theme.useToken();

  const [initialValues, setInitialValues] = useState({
    address: { city: "" },
    role: { id: "" },
  });

  const pageStyle = {
    maxWidth: "none",
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
    height: "100%",
  };

  const fetchCooperants = async (values) => {
    const response = await axiosInstance.get(
      `/api/v1/cooperants/find-by?searchTerm=${values.searchTerm}&page=${
        pageNumber - 1
      }&size=${pageSize}`
    );

    setTableData(
      response?.data?.content.map((element) => {
        return {
          ...element,
          key: element.id,
          addressStr: element.address.street
            ? `${element.address.street} ${
                element.address.houseNumber ? element.address.houseNumber + ",": ""
              } ${
                element.address.postNumber ? element.address.postNumber : ""
              } ${element.address.city ? element.address.city : ""}`
            : "",
        };
      })
    );
    setTotalRows(response?.data?.totalElements);
  };

  useEffect(() => {
    fetchCooperants({ searchTerm: "" });
  }, [pageNumber, pageSize]);

  const onFormFilterSubmit = async (values) => {
    fetchCooperants(values);
  };

  const onCooperantDataSubmit = (values) => {
    if (mode === "insert") {
      axiosInstance
        .post("/api/v1/cooperants", values)
        .then((res) => {
          setLoading(false);
          setCooperantAdministrationModalOpened(false);
          cooperantDataForm.resetFields();
          toast.success("Kooperant uspješno spremljen!");
          fetchCooperants({ searchTerm: "" });
        })
        .catch((ex) => {
          toast.error("Došlo je do greške prilikom spremanja kooperanta!");
        });
    } else if (mode === "edit") {
      axiosInstance
        .patch("/api/v1/cooperants", values)
        .then((res) => {
          setLoading(false);
          setCooperantAdministrationModalOpened(false);
          cooperantDataForm.resetFields();
          toast.success("Kooperant uspješno ažuriran!");
          fetchCooperants({ searchTerm: "" });
        })
        .catch((ex) => {
          toast.error("Došlo je do greške prilikom ažuriranja kooperanta!");
        });
    }
  };

  const onFilterChanged = async (pagination, filters, sorter) => {
    setPageNumber(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleSubmitCooperant = async () => {
    try {
      const validationResult = await cooperantDataForm.validateFields();
      if (!validationResult.errorFields) {
        cooperantDataForm.submit();
        setLoading(true);
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const cooperantDataFormTitle = () => {
    if (mode === "insert") {
      return "Novi kooperant";
    } else if (mode === "edit") {
      return "Uređivanje kooperanta";
    } else {
      return "Pregled detalja kooperanta";
    }
  };

  return (
    <div className={"cooperant-page"} style={pageStyle}>
      <h3>Administracija kooperanata</h3>
      <CooperantAdministrationFilter
        form={filterForm}
        onFormFilterSubmit={onFormFilterSubmit}
      ></CooperantAdministrationFilter>
      <Flex vertical={false} justify="right" className="add-cooperant-wrapper">
        <Button
          type="dashed"
          icon={<PlusOutlined />}
          style={{ marginTop: "32px" }}
          onClick={() => {
            cooperantDataForm.resetFields();
            setMode("insert");
            setCooperantAdministrationModalOpened(true);
          }}
        >
          Novi kooperant
        </Button>
      </Flex>
      <CooperantAdministrationTable
        tableData={tableData}
        onFilterChanged={(pagination, filters, sorter) =>
          onFilterChanged(pagination, filters, sorter)
        }
        setMode={setMode}
        setCooperantAdministrationModalOpened={
          setCooperantAdministrationModalOpened
        }
        setRowData={setRowData}
        style={{ marginTop: "32px" }}
        pageNumber={pageNumber}
        pageSize={pageSize}
        totalRows={totalRows}
        bordered
        refreshData={() => fetchCooperants({ searchTerm: "" })}
      />
      <ModalComponent
        title={cooperantDataFormTitle()}
        show={cooperantAdministrationModalOpened}
        onOk={handleSubmitCooperant}
        onCancel={() => {
          setCooperantAdministrationModalOpened(false);
        }}
        loading={loading}
        showFooter={mode !== "preview"}
        width={"580px"}
      >
        <CooperantAdministrationForm
          form={cooperantDataForm}
          mode={mode}
          onFinish={onCooperantDataSubmit}
          initialValues={mode === "insert" ? initialValues : rowData}
          disabled={mode === "preview"}
        ></CooperantAdministrationForm>
      </ModalComponent>
    </div>
  );
};

export default CooperantAdministrationPage;
